import {
  forwardRef,
  type InputHTMLAttributes,
  type DetailedHTMLProps,
} from "react";
import cn from "classnames";
import { ErrorMessage } from "../ErrorMessage";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  block?: boolean;
  isError?: boolean;
  errorMessage?: string;
  colorType?: "gray" | "white";
  inputClassNames?: string;
};

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      block,
      isError,
      errorMessage,
      className,
      colorType = "gray",
      inputClassNames,
      ...props
    },
    ref
  ) => {
    const { name } = props;

    return (
      <div
        className={cn(`flex flex-col gap-2 ${className}`, {
          "w-full": block,
        })}
      >
        {label && (
          <label className="text-black text-lg-semibold" htmlFor={name}>
            {label}
          </label>
        )}
        <div className="flex flex-col gap-2">
          <input
            className={cn(
              `text-charcoal_600 text-lg-medium py-3 px-4 rounded-lg border outline-none placeholder:text-charcoal_300 ${inputClassNames}`,
              {
                "border-charcoal_300 bg-charcoal_50": colorType === "gray",
                "border-charcoal_200 bg-white": colorType === "white",
                "!border-cerise_300 !bg-cerise_50": isError,
              }
            )}
            id={name}
            ref={ref}
            {...props}
          />
          {isError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";
