import type { PropsWithChildren } from "react";
import { AnimatePresence } from "framer-motion";
import { Portal } from "../Portal";
import { LoadingSpinner } from "../LoadingSpinner";
import { AnimateAppearSection } from "../AnimateAppearSection";

interface Props {
  isOpen: boolean;
  classNames?: string;
}

export const LoadingModal = ({
  isOpen,
  classNames,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Portal id="modal">
      <AnimatePresence>
        {isOpen && (
          <div
            className="fixed inset-0 py-16 justify-center flex items-center z-modal"
            style={{
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <AnimateAppearSection
              className={`${classNames} flex flex-col p-16 bg-white rounded-lg h-fit w-fit justify-center items-center`}
            >
              <LoadingSpinner color="charcoal_400" size="lg" />
              {children}
            </AnimateAppearSection>
          </div>
        )}
      </AnimatePresence>
    </Portal>
  );
};
