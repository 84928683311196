import {
  type HTMLAttributes,
  type PropsWithChildren,
  useState,
  Children,
} from "react";
import { getPaddingStyle } from "@landwich/shared/utils";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AnimateDropdownWrapper } from "../AnimateDropdownWrapper";
import useDropdownContext, { DropdownContext } from "./useDropdownContext";

export const Dropdown = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <DropdownContext.Provider value={{ isOpen, onOpenChange }}>
      <DropdownMenu.Root
        defaultOpen={false}
        onOpenChange={onOpenChange}
        open={isOpen}
      >
        {children}
      </DropdownMenu.Root>
    </DropdownContext.Provider>
  );
};

interface DropdownTriggerProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
  paddingX?: number;
  paddingY?: number;
  color: "charcoal" | "white";
  radius?: "rounded" | "rounded-lg";
}

const DropDownTrigger = ({
  children,
  asChild = true,
  radius = "rounded",
  className,
  color,
  paddingX = 4,
  paddingY = 2,
  ...rest
}: PropsWithChildren<DropdownTriggerProps>) => {
  const MAP_COLOR = {
    charcoal:
      "border-charcoal_400 hover:bg-charcoal_50 hover:border-charcoal_500",
    white: "bg-white border-charcoal_200 hover:border-charcoal_300",
  };

  const dropdownStyle = getPaddingStyle({ paddingX, paddingY });

  return (
    <DropdownMenu.Trigger asChild={asChild}>
      <div
        className={`flex items-center border hover:cursor-pointer ${radius} ${MAP_COLOR[color]} ${className}`}
        {...rest}
        style={dropdownStyle}
      >
        {children}
      </div>
    </DropdownMenu.Trigger>
  );
};

const DropdownCustomTrigger = ({ children }: PropsWithChildren) => {
  return (
    <DropdownMenu.Trigger
      style={{
        outline: "none",
      }}
    >
      {children}
    </DropdownMenu.Trigger>
  );
};

interface DropdownMenuContainerProps {
  align?: "start" | "center" | "end";
  className?: string;
  width?: number;
}

const DropdownMenuContainer = ({
  align = "start",
  width,
  children,
  className,
}: PropsWithChildren<DropdownMenuContainerProps>) => {
  const spacedChildren = Children.map(children, (child, index) => (
    <>
      {index > 0 && <div className="border-b-[1px]" key={`spacer-${index}`} />}
      {child}
    </>
  ));

  return (
    <AnimateDropdownWrapper
      align={align}
      className={`rounded-lg border-[1px] border-charcoal_200 bg-white hover:cursor-pointer overflow-hidden ${className}`}
      style={{
        width: width ? `${width}px` : "var(--radix-popper-anchor-width)",
      }}
      zIndexClass="z-dropdown"
    >
      {spacedChildren}
    </AnimateDropdownWrapper>
  );
};

interface DropdownMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  paddingX?: number;
  paddingY?: number;
  handleMenuItemClick?: ({
    isOpen,
    onOpenChange,
  }: {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
  }) => void;
}

const DropDownMenuItem = ({
  paddingX = 4,
  paddingY = 2,
  children,
  className,
  handleMenuItemClick,
}: PropsWithChildren<DropdownMenuItemProps>) => {
  const { onOpenChange, isOpen } = useDropdownContext();

  const dropdownStyle = getPaddingStyle({ paddingX, paddingY });

  return (
    <DropdownMenu.DropdownMenuItem
      className={`${className} hover:bg-charcoal_100 border-charcoal_200 focus:outline-none`}
      onClick={(e) => {
        e.stopPropagation();
        if (handleMenuItemClick) {
          handleMenuItemClick({ isOpen, onOpenChange });
        }
      }}
      style={dropdownStyle}
    >
      {children}
    </DropdownMenu.DropdownMenuItem>
  );
};

Dropdown.Trigger = DropDownTrigger;
Dropdown.CustomTrigger = DropdownCustomTrigger;
Dropdown.MenuContainer = DropdownMenuContainer;
Dropdown.MenuItem = DropDownMenuItem;
