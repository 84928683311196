import { AnimatePresence, motion, type HTMLMotionProps } from "framer-motion";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { type PropsWithChildren } from "react";

interface Props extends HTMLMotionProps<"div"> {
  align?: "start" | "center" | "end";
  sideOffset?: number;
  zIndexClass?: string;
}

export const AnimateDropdownWrapper = ({
  align,
  children,
  sideOffset = 10,
  zIndexClass,
  ...rest
}: PropsWithChildren<Props>) => {
  const dropdownVariants = {
    hidden: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.15 },
    },
    visible: {
      opacity: 1,
      height: "auto",
      transition: { duration: 0.3 },
    },
  };

  return (
    <AnimatePresence>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align={align}
          className={`${zIndexClass}`}
          sideOffset={sideOffset}
        >
          <motion.div
            animate="visible"
            exit="hidden"
            initial="hidden"
            variants={dropdownVariants}
            {...rest}
          >
            {children}
          </motion.div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </AnimatePresence>
  );
};
