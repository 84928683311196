import type { ColorKeyType } from "@landwich/shared/types";
import { colors } from "@landwich/styles";

interface Props {
  size: "lg" | "sm";
  color: ColorKeyType;
}

export const LoadingSpinner = ({ size, color }: Props) => {
  return (
    <div className="w-fit h-fit animate-spin">
      {size === "lg" ? (
        <svg
          fill="none"
          height="64"
          viewBox="0 0 64 64"
          width="64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M58 30.8208C57.9981 35.4881 56.7399 40.0689 54.3573 44.0823C51.9748 48.0957 48.5559 51.3938 44.4593 53.6304C40.3628 55.8671 35.7397 56.9597 31.0753 56.7937C26.411 56.6277 21.8773 55.2092 17.9501 52.6871C14.0229 50.1649 10.847 46.6321 8.75582 42.4595C6.66461 38.2869 5.73516 33.6283 6.06502 28.9726C6.39489 24.3169 7.9719 19.8359 10.6305 15.9998C13.2892 12.1637 16.9314 9.11403 21.175 7.1708C21.6598 6.95799 22.2086 6.94349 22.7039 7.13041C23.1993 7.31732 23.6017 7.69079 23.825 8.1708C23.9337 8.4104 23.9941 8.66911 24.0027 8.93208C24.0112 9.19506 23.9678 9.45715 23.8749 9.70332C23.782 9.94949 23.6415 10.1749 23.4613 10.3667C23.2812 10.5584 23.0649 10.7128 22.825 10.8208C18.2633 12.914 14.5544 16.504 12.3138 20.9952C10.0733 25.4863 9.43571 30.6086 10.507 35.512C11.5782 40.4154 14.2939 44.805 18.2034 47.9526C22.1128 51.1001 26.981 52.8163 32 52.8163C37.019 52.8163 41.8872 51.1001 45.7966 47.9526C49.7061 44.805 52.4218 40.4154 53.493 35.512C54.5643 30.6086 53.9268 25.4863 51.6862 20.9952C49.4456 16.504 45.7367 12.914 41.175 10.8208C40.9351 10.7128 40.7189 10.5584 40.5387 10.3667C40.3585 10.1749 40.218 9.94949 40.1251 9.70332C40.0322 9.45715 39.9888 9.19506 39.9973 8.93208C40.0059 8.66911 40.0663 8.4104 40.175 8.1708C40.3983 7.69079 40.8008 7.31732 41.2961 7.13041C41.7914 6.94349 42.3402 6.95799 42.825 7.1708C47.3468 9.25488 51.1781 12.5888 53.8669 16.7793C56.5557 20.9698 57.9898 25.8419 58 30.8208Z"
            fill={colors[color]}
          />
        </svg>
      ) : (
        <svg
          fill="none"
          height="17"
          width="17"
          viewBox="0 0 17 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 8.21701C15.9995 9.43514 15.6607 10.6307 15.0193 11.6782C14.3778 12.7256 13.4573 13.5864 12.3544 14.1701C11.2515 14.7539 10.0068 15.039 8.75105 14.9957C7.49526 14.9524 6.27465 14.5822 5.21733 13.9239C4.16001 13.2657 3.30497 12.3436 2.74195 11.2546C2.17893 10.1656 1.9287 8.94974 2.01751 7.73465C2.10632 6.51956 2.5309 5.35006 3.24669 4.34887C3.96248 3.34769 4.94308 2.55174 6.08558 2.04458C6.21609 1.98904 6.36385 1.98525 6.49721 2.03403C6.63057 2.08282 6.73891 2.18029 6.79904 2.30557C6.82831 2.3681 6.84456 2.43562 6.84687 2.50426C6.84918 2.57289 6.83749 2.64129 6.81248 2.70554C6.78747 2.76979 6.74963 2.82862 6.70112 2.87867C6.65262 2.92872 6.5944 2.96899 6.52981 2.99719C5.30165 3.54349 4.30311 4.48046 3.69988 5.65261C3.09665 6.82477 2.925 8.16163 3.21342 9.44137C3.50184 10.7211 4.23298 11.8668 5.28552 12.6883C6.33806 13.5097 7.64872 13.9576 9 13.9576C10.3513 13.9576 11.6619 13.5097 12.7145 12.6883C13.767 11.8668 14.4982 10.7211 14.7866 9.44137C15.075 8.16163 14.9034 6.82477 14.3001 5.65261C13.6969 4.48046 12.6984 3.54349 11.4702 2.99719C11.4056 2.96899 11.3474 2.92872 11.2989 2.87867C11.2504 2.82862 11.2125 2.76979 11.1875 2.70554C11.1625 2.64129 11.1508 2.57289 11.1531 2.50426C11.1554 2.43562 11.1717 2.3681 11.201 2.30557C11.2611 2.18029 11.3694 2.08282 11.5028 2.03403C11.6361 1.98525 11.7839 1.98904 11.9144 2.04458C13.1318 2.5885 14.1633 3.45864 14.8872 4.55231C15.6111 5.64598 15.9973 6.91756 16 8.21701Z"
            fill={colors[color]}
          />
        </svg>
      )}
    </div>
  );
};
