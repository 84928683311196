interface Props {
  paddingX: number;
  paddingY: number;
}

export const getPaddingStyle = ({ paddingX, paddingY }: Props) => {
  return {
    paddingLeft: `${paddingX * 0.25}rem`,
    paddingRight: `${paddingX * 0.25}rem`,
    paddingTop: `${paddingY * 0.25}rem`,
    paddingBottom: `${paddingY * 0.25}rem`,
  };
};
