import type { GetPageFontsResponseDataDto } from "@our-pairy/landwich-api-types/api";
import { getFontKey } from "./getFontKey";

export const getFontInfoObject = (
  fontItemList: GetPageFontsResponseDataDto[]
) => {
  return (
    fontItemList?.reduce((acc, { name, family, variant, size }) => {
      const fontKey = getFontKey({ fontFamily: family, fontWeight: variant });
      return {
        ...acc,
        [name]: {
          desktop: {
            fontSize: size.desktop,
            fontFamily: fontKey,
          },
          mobile: {
            fontSize: size.mobile,
            fontFamily: fontKey,
          },
        },
      };
    }, {}) ?? {}
  );
};
