import type { HTMLAttributes, PropsWithChildren } from "react";

export const Badge = ({
  children,
  className,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      className={`rounded-full px-3 py-2 text-sm-medium ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};
