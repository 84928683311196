import { motion, type HTMLMotionProps } from "framer-motion";
import { type PropsWithChildren, forwardRef } from "react";

export const AnimateAppearSection = forwardRef<
  HTMLElement,
  PropsWithChildren<HTMLMotionProps<"section">>
>(({ children, ...rest }, ref) => {
  return (
    <motion.section
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      ref={ref}
      transition={{
        duration: 0.3,
        ease: "easeIn",
      }}
      {...rest}
    >
      {children}
    </motion.section>
  );
});

AnimateAppearSection.displayName = "AnimateAppearSection";
