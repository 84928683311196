import { type HtmlHTMLAttributes, type PropsWithChildren } from "react";
import type { ColorKeyType } from "@landwich/shared/types";
import { Icon } from "../Icon";

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  activeColor?: ColorKeyType;
  unActiveColor?: ColorKeyType;
  isToggle: boolean;
  handleToggleAction: () => void;
}

export const Toggle = ({
  isToggle,
  children,
  handleToggleAction,
  activeColor = "charcoal_400",
  unActiveColor = "charcoal_200",
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <div {...rest}>
      {children}
      <button
        className="h-fit w-fit hover:cursor-pointer transition-all"
        onClick={handleToggleAction}
        type="button"
      >
        {isToggle ? (
          <Icon color={activeColor} icon="ph:toggle-right-fill" size={24} />
        ) : (
          <Icon color={unActiveColor} icon="ph:toggle-left-fill" size={24} />
        )}
      </button>
    </div>
  );
};
