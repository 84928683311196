interface Props {
  r: number;
  g: number;
  b: number;
  a?: number;
}

export const toHex = (n: number) => {
  const hex = n.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbaToHex = ({ r, g, b, a = 1 }: Props) => {
  const alpha = Math.round(a * 255);
  const alphaHex = toHex(alpha);

  return `#${toHex(r)}${toHex(g)}${toHex(b)}${alphaHex}`;
};
