export const pagesFontSize = {
  "desktop-heading1": [
    "4rem",
    {
      fontWeight: 700,
    },
  ],
  "desktop-heading2": [
    "3rem",
    {
      fontWeight: 700,
    },
  ],
  "desktop-heading3": [
    "2.25rem",
    {
      fontWeight: 700,
    },
  ],
  "desktop-heading4": [
    "1.75rem",
    {
      fontWeight: 700,
    },
  ],
  "desktop-heading5": [
    "1.5rem",
    {
      fontWeight: 700,
    },
  ],
  "desktop-body1": [
    "1.25rem",
    {
      fontWeight: 400,
    },
  ],
  "desktop-body1Bold": [
    "1.25rem",
    {
      fontWeight: 700,
    },
  ],
  "desktop-body2": [
    "1rem",
    {
      fontWeight: 400,
    },
  ],
  "mobile-heading1": [
    "2.25rem",
    {
      fontWeight: 700,
    },
  ],
  "mobile-heading2": [
    "2rem",
    {
      fontWeight: 700,
    },
  ],
  "mobile-heading3": [
    "1.75rem",
    {
      fontWeight: 700,
    },
  ],
  "mobile-heading4": [
    "1.5rem",
    {
      fontWeight: 700,
    },
  ],
  "mobile-heading5": [
    "1.25rem",
    {
      fontWeight: 700,
    },
  ],
  "mobile-body1": [
    "1rem",
    {
      fontWeight: 400,
    },
  ],
  "mobile-body1Bold": [
    "1rem",
    {
      fontWeight: 700,
    },
  ],
  "mobile-body2": [
    "0.875rem",
    {
      fontWeight: 400,
    },
  ],
  "tablet-heading1": [
    "1.25rem",
    {
      fontWeight: 700,
    },
  ],
  "tablet-heading2": [
    "1rem",
    {
      fontWeight: 700,
    },
  ],
  "tablet-heading3": [
    "0.75rem",
    {
      fontWeight: 700,
    },
  ],
  "tablet-heading4": [
    "0.625rem",
    {
      fontWeight: 700,
    },
  ],
  "tablet-heading5": [
    "0.5rem",
    {
      fontWeight: 700,
    },
  ],
  "tablet-body1": [
    "0.5rem",
    {
      fontWeight: 400,
    },
  ],
  "tablet-body1Bold": [
    "0.5rem",
    {
      fontWeight: 700,
    },
  ],
  "tablet-body2": [
    "0.313rem",
    {
      fontWeight: 400,
    },
  ],
} as const;
