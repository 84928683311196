import {
  type FieldValues,
  type UseFormReturn,
  type SubmitHandler,
  type Resolver,
  type DefaultValues,
  useForm,
} from "react-hook-form";

interface Props<TFormValues extends FieldValues> {
  className?: string;
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  resolver?: Resolver<TFormValues>;
  defaultValues?: DefaultValues<TFormValues>;
}

export const Form = <TFormValues extends FieldValues>({
  className,
  onSubmit,
  children,
  resolver,
  defaultValues,
}: Props<TFormValues>): JSX.Element => {
  const methods = useForm<TFormValues>({
    resolver,
    defaultValues,
  });

  return (
    <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
      {children(methods)}
    </form>
  );
};
