import type { PropsWithChildren } from "react";
import cn from "classnames";
import { useRef } from "react";
import { useBodyScrollLock } from "@landwich/shared/hooks";
import { motion, AnimatePresence } from "framer-motion";
import type { IconNames } from "../Icon";
import { Icon } from "../Icon";
import { Portal } from "../Portal";

export interface BasicModalProps {
  isOpen: boolean;
  isFitContent?: boolean;
  className?: string;
}

export interface BasicModalHeaderProps {
  icon: IconNames;
  title: string;
}

export interface BasicModalBodyProps {
  className?: string;
}

export interface BasicModalFooterProps {
  className?: string;
}

export const BasicModal = ({
  isOpen,
  isFitContent,
  className,
  children,
}: PropsWithChildren<BasicModalProps>): JSX.Element => {
  useBodyScrollLock(isOpen);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <Portal id="modal">
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              animate={{ opacity: 1 }}
              className={cn(
                `flex flex-col gap-16 fixed left-1/2 top-1/2 translate-x-[-50%] bg-white z-modal translate-y-[-50%] rounded-lg border border-charcoal_200 shadow-modal p-16 ${className}`
              )}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              ref={modalRef}
              style={{
                height: isFitContent
                  ? "fit-content"
                  : "min(calc(100vh - 128px), 850px)",
                minHeight: isFitContent ? "fit-content" : "480px",
              }}
              transition={{
                duration: 0.3,
                ease: "easeIn",
              }}
            >
              {children}
            </motion.div>
            <div
              className="fixed inset-0 z-modal-dimmer h-screen w-screen"
              style={{
                background: "rgba(0,0,0,0.5)",
              }}
            />
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};

const ModalHeader = ({ icon, title }: BasicModalHeaderProps): JSX.Element => {
  return (
    <header className="flex flex-col gap-4 items-center">
      <Icon color="black" icon={icon} size={38} />
      <div className="text-center text-3xl-semibold text-black">{title}</div>
    </header>
  );
};

const ModalBody = ({
  className,
  children,
}: PropsWithChildren<BasicModalBodyProps>): JSX.Element => {
  return (
    <div className={`h-full overflow-scroll ${className}`}>{children}</div>
  );
};

const ModalFooter = ({
  className,
  children,
}: PropsWithChildren<BasicModalFooterProps>): JSX.Element => {
  return <footer className={`w-full ${className}`}>{children}</footer>;
};

BasicModal.Header = ModalHeader;
BasicModal.Body = ModalBody;
BasicModal.Footer = ModalFooter;
