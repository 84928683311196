interface Props {
  fontKey: string;
  src: string;
}

export const loadFontFamily = async ({ src, fontKey }: Props) => {
  if (src === "") {
    return;
  }

  const font = new FontFace(fontKey, `url(${src})`);
  const loadFontItem = await font.load();
  document.fonts.add(loadFontItem);
};
