export const colors = {
  inherit: "inherit",
  transparent: "transparent",
  black: "#000000",
  white: "#ffffff",
  viridian_50: "#EAF6F1",
  viridian_100: "#D8F4E7",
  viridian_200: "#BBEAD4",
  viridian_300: "#91E0BA",
  viridian_400: "#6BC69A",
  viridian_500: "#68A387",
  viridian_600: "#498166",
  viridian_700: "#306248",
  viridian_800: "#214A35",
  viridian_900: "#143222",
  cerise_50: "#FAF2F5",
  cerise_100: "#F1D5DF",
  cerise_200: "#ECB3C7",
  cerise_300: "#EA96B3",
  cerise_400: "#DB7095",
  cerise_500: "#AF5877",
  cerise_600: "#832F4D",
  cerise_700: "#66213A",
  cerise_800: "#54172D",
  cerise_900: "#3C0C1D",
  charcoal_50: "#F5F9FB",
  charcoal_100: "#EBF1F4",
  charcoal_200: "#D9E3E7",
  charcoal_300: "#B4C7CE",
  charcoal_400: "#90A5AD",
  charcoal_500: "#718188",
  charcoal_600: "#5E6A6F",
  charcoal_700: "#445156",
  charcoal_800: "#2F3D43",
  charcoal_900: "#0D1214",
  cobalt_50: "#F2F6FE",
  cobalt_100: "#CCDBFF",
  cobalt_200: "#A3BCF6",
  cobalt_300: "#80A6FF",
  cobalt_400: "#5A88F3",
  cobalt_500: "#3A6CE1",
  cobalt_600: "#325FC7",
  cobalt_700: "#244BA6",
  cobalt_800: "#0D2F7E",
  cobalt_900: "#021F61",
};
