import type { PropsWithChildren, ReactNode, RefObject } from "react";
import { AnimatePresence } from "framer-motion";
import { Portal } from "../Portal";
import { Icon } from "../Icon";
import { AnimateAppearSection } from "../AnimateAppearSection";

interface Props {
  isOpen: boolean;
  title: string;
  closeModal: () => void;
  classNames?: string;
  level?: number;
  modalRef?: RefObject<HTMLDivElement>;
}

export const AlertModal = ({
  classNames = "",
  isOpen,
  title,
  children,
  closeModal,
  level = 0,
  modalRef,
}: PropsWithChildren<Props>) => {
  return (
    <Portal id="modal">
      <AnimatePresence>
        {isOpen && (
          <div
            className="fixed inset-0 py-16 justify-center flex items-center"
            style={{
              background: "rgba(0, 0, 0, 0.75)",
              zIndex: `${level + 100}`,
            }}
          >
            <AnimateAppearSection
              className={`${classNames} flex flex-col gap-2 p-6 bg-white rounded-lg h-fit w-fit`}
              ref={modalRef}
            >
              <div className="flex justify-between w-full">
                <h2 className="text-lg-semibold text-cerise_500">{title}</h2>
                <button onClick={closeModal} type="button">
                  <Icon color="charcoal_400" icon="ph:x" size={19} />
                </button>
              </div>
              <div className="flex flex-col gap-6">{children}</div>
            </AnimateAppearSection>
          </div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

const AlertModalBody = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return <div className={`max-w-[476px] ${className}`}>{children}</div>;
};

const AlertModalContent = ({
  title,
  description,
}: {
  title?: string | ReactNode;
  description: string;
}) => {
  return (
    <article className="flex flex-col gap-2">
      {title && <p className="text-xl-medium whitespace-pre-line">{title}</p>}
      <span className="text-md-medium text-charcoal_500 whitespace-pre-line">
        {description}
      </span>
    </article>
  );
};

const AlertModalFooter = ({ children }: PropsWithChildren) => {
  return <div className="w-fit flex gap-4">{children}</div>;
};

AlertModal.Body = AlertModalBody;
AlertModal.Content = AlertModalContent;
AlertModal.Footer = AlertModalFooter;
