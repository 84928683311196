import { useEffect } from "react";

interface Props {
  escCallback: () => void;
}
export const useEscKey = ({ escCallback }: Props): void => {
  useEffect(() => {
    const escHandler = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        escCallback();
      }
    };

    document.addEventListener("keydown", escHandler);

    return () => {
      document.removeEventListener("keydown", escHandler);
    };
  }, [escCallback]);
};
