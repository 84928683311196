import type { HTMLAttributes, PropsWithChildren } from "react";
import { AnimatePresence, type HTMLMotionProps } from "framer-motion";
import { Portal } from "../Portal";
import { Icon } from "../Icon";
import { Button } from "../Button";
import { AnimateAppearSection } from "../AnimateAppearSection";

type MotionDivAttributeType = HTMLMotionProps<"div"> &
  HTMLAttributes<HTMLDivElement>;

interface Props extends MotionDivAttributeType {
  isOpen: boolean;
}

export const ErrorAlertModal = ({
  isOpen,
  className,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Portal id="modal">
      <AnimatePresence>
        {isOpen && (
          <div
            className="fixed inset-0 py-16 justify-center flex items-center z-modal"
            style={{
              background: "rgba(0, 0, 0, 0.75)",
            }}
          >
            <AnimateAppearSection
              className={`${className} flex flex-col p-16 bg-white rounded-lg h-fit w-fit justify-center items-center gap-4 scrollbar-hide`}
              {...rest}
            >
              <Icon color="cerise_500" icon="ph:lightning-slash" size={38} />
              <div className="flex flex-col gap-8">{children}</div>
            </AnimateAppearSection>
          </div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

const ErrorAlertModalContent = ({
  children,
  className,
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      className={`${className} text-xl-semibold whitespace-pre-line text-center text-cerise_500`}
    >
      {children}
    </div>
  );
};

interface ErrorAlertModalFooterProps extends HTMLAttributes<HTMLDivElement> {
  handleCancelClick: () => void;
  handleRetryClick: () => void;
}

const ErrorAlertModalFooter = ({
  className,
  handleCancelClick,
  handleRetryClick,
  ...rest
}: ErrorAlertModalFooterProps) => {
  return (
    <div className={`${className} flex gap-2 justify-center`} {...rest}>
      <Button
        className="w-[115px]"
        color="white"
        onClick={handleCancelClick}
        rounded="rounded-full"
        textVariant="text-lg-medium"
      >
        Cancel
      </Button>
      <Button
        className="w-[200px]"
        color="black"
        onClick={handleRetryClick}
        rounded="rounded-full"
        textVariant="text-lg-medium"
      >
        Retry
      </Button>
    </div>
  );
};

ErrorAlertModal.Content = ErrorAlertModalContent;
ErrorAlertModal.Footer = ErrorAlertModalFooter;
