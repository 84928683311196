import { type ToastItemType } from "@landwich/shared/types";
import { isUndefined } from "@landwich/shared/utils";
import { Icon } from "../Icon";

export const Toast = ({
  type,
  message,
  handleOnError,
}: Omit<ToastItemType, "id">) => {
  if (type === "error") {
    return (
      <div className="rounded-full border-[1px] border-cerise_500 px-8 py-4 bg-white shadow-lg flex gap-2 items-center w-fit">
        <Icon color="cerise_500" icon="ph:warning" size={19} />
        <span className="text-lg-medium text-cerise_500">{message}</span>
        {!isUndefined(handleOnError) && (
          <button onClick={handleOnError} type="button">
            <Icon color="cerise_500" icon="ph:caret-right" size={19} />
          </button>
        )}
      </div>
    );
  }

  if (handleOnError) {
    throw Error("handleOnError는 type이 error일 때만 넣어주어야 합니다");
  }

  return (
    <div className="rounded-full border-[1px] border-viridian_500 bg-white px-8 py-4 shadow-lg flex gap-2 items-center w-fit">
      <Icon color="viridian_500" icon="ph:seal-check" size={19} />
      <span className="text-lg-medium text-viridian_500">{message}</span>
    </div>
  );
};
