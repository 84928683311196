import { createPageId } from "@our-pairy/landwich-types/utils";
// TODO: 타입 수정
import type {
  ComCtaItems,
  ComFeatureItems,
  ComFlowItems,
  ComIntroFeatures,
  ElementUnion,
  TableContent,
  TopCompetitorListItems,
  TopCtaItems,
  TopIntroItems,
  TopPriorityItems,
} from "@our-pairy/landwich-types/pages";
import type { PageType } from "../../types";

type DraggableItemsUnion =
  | ComIntroFeatures
  | TableContent
  | ComFlowItems
  | ComFeatureItems
  | ComCtaItems
  | TopIntroItems
  | TopPriorityItems
  | TopCompetitorListItems
  | TopCtaItems;

export const getNewDraggableItem = ({
  isCompetitorListSection,
  hasNonDraggableItemSection,
  draggableItems,
  layout,
}: {
  isCompetitorListSection: boolean;
  hasNonDraggableItemSection: boolean;
  draggableItems: DraggableItemsUnion | undefined;
  layout: PageType["layout"];
}) => {
  if (!draggableItems) return null;

  const competitorDraggableItem = {
    ...draggableItems,
    node: (draggableItems as TopCompetitorListItems).node.filter(
      (competitor) => !competitor.id.includes("MyProductNode")
    ),
  };

  const item = isCompetitorListSection
    ? competitorDraggableItem
    : draggableItems;

  const lastIndex = hasNonDraggableItemSection
    ? item.node.length - 2
    : item.node.length - 1;

  const draggableItem = item.node[lastIndex];

  const uniqueId = createPageId(layout);
  return updateIds(draggableItem, uniqueId);
};

const updateIds = (item: ElementUnion, uniqueId: string) => {
  if (typeof item !== "object" || item === null) {
    return item;
  }

  const updatedItem = { ...item };

  if ("id" in updatedItem) {
    updatedItem.id = `${updatedItem.id}${uniqueId}`;
  }

  if (updatedItem.type === "flex") {
    updatedItem.node = updatedItem.node.map((node) =>
      updateIds(node, uniqueId)
    );
  }

  return updatedItem;
};
