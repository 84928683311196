import { useEffect, useState } from "react";
import { loadFontFamily } from "../utils";

interface Props {
  fontList: { fontKey: string; src: string }[];
}

export const useFontLoad = ({ fontList }: Props) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  const handleFontLoaded = async () => {
    const fontPromise = fontList?.map(async ({ fontKey, src }) => {
      return loadFontFamily({
        src,
        fontKey,
      });
    });

    await Promise.all(fontPromise);
  };

  useEffect(() => {
    if (fontList?.length === 0) {
      setIsFontLoaded(true);
      return;
    }

    void handleFontLoaded().then(() => {
      setIsFontLoaded(true);
    });
  }, [fontList]);

  return {
    isFontLoaded,
  };
};
