import {
  type DetailedHTMLProps,
  forwardRef,
  type TextareaHTMLAttributes,
} from "react";
import { isUndefined } from "@landwich/shared/utils";
import cn from "classnames";
import { ErrorMessage } from "../ErrorMessage";

interface Props
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  isError?: boolean;
  block?: boolean;
  errorMessage?: string;
  label?: string;
  subLabel?: string;
  maxLength?: number;
  className?: string;
  value: string;
}

export const TextField = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      block,
      className,
      isError,
      errorMessage,
      maxLength,
      subLabel,
      value,
      ...props
    },
    ref
  ) => {
    const { name } = props;

    return (
      <label
        className={cn(`flex flex-col h-fit `, {
          "w-full": block,
        })}
      >
        {maxLength ? (
          <div className="flex items-end justify-between pb-2">
            {!isUndefined(label) && (
              <label className="text-black text-lg-semibold" htmlFor={name}>
                {label}
              </label>
            )}
            <span className="text-charcoal_300 text-md-medium">
              {value?.length} / {maxLength} characters
            </span>
          </div>
        ) : (
          <div className="pb-2">
            {!isUndefined(label) && (
              <label className="text-black text-lg-semibold" htmlFor={name}>
                {label}
              </label>
            )}
            {!isUndefined(subLabel) && (
              <div className="text-md-medium text-charcoal_300 pb-2">
                {subLabel}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col gap-2">
          <textarea
            className={cn(
              `text-lg-medium py-3 px-4 rounded-lg border outline-none ${className}`,
              {
                "border-cerise_300 bg-cerise_50 text-cerise_500 placeholder:text-cerise_200": isError,
                "text-charcoal_600 border-charcoal_300 bg-charcoal_50 placeholder:text-charcoal_300" : !isError,
              }
            )}
            id={name}
            ref={ref}
            maxLength={maxLength}
            value={value}
            {...props}
          />
          {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
      </label>
    );
  }
);

TextField.displayName = "TextField";
