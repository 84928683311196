import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

interface Props {
  id: string;
}

export const Portal = ({
  children,
  id,
}: PropsWithChildren<Props>): JSX.Element => {
  const el = document.getElementById(id);

  if (!el) return <></>;

  return createPortal(children, el);
};
