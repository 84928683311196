import { createContext, useContext } from "react";

interface DropdownContextType {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const DropdownContext = createContext<null | DropdownContextType>(null);

const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (context === null) {
    throw Error("not found dropdown context error");
  }

  const { isOpen, onOpenChange } = context;

  return {
    isOpen,
    onOpenChange,
  };
};

export default useDropdownContext;
