import { Icon as Iconify } from "@iconify/react";
import { useMemo } from "react";
import { colors } from "@landwich/styles";
import type { IconProps, IconNames } from "./index.types";

export const Icon = ({
  size,
  icon,
  color = "inherit",
  ...rest
}: IconProps): JSX.Element => {
  const width = useMemo(
    () => (typeof size === "number" ? size : size.width),
    [size]
  );
  const height = useMemo(
    () => (typeof size === "number" ? size : size.height),
    [size]
  );

  if (color === "default") {
    return <Iconify height={height} icon={icon} width={width} {...rest} />;
  }

  return (
    <div style={{ width, height, color: colors[color] }}>
      <Iconify
        color={colors[color]}
        height={height}
        icon={icon}
        width={width}
        {...rest}
      />
    </div>
  );
};

export type { IconNames, IconProps };
