interface Props {
  value: string;
  caseBy: Record<string, JSX.Element>;
  defaultComponent: JSX.Element;
}

export const SwitchCase = ({
  value,
  caseBy,
  defaultComponent,
}: Props): JSX.Element => {
  return caseBy[value] || defaultComponent;
};
