import type { RefObject } from "react";
import { useEffect } from "react";

interface Props<T extends HTMLElement = HTMLElement> {
  targetRef: RefObject<T>;
  restRefs?: RefObject<T>[];
  handler: (event: Event) => void;
}

export const useOnClickOutside = ({
  targetRef,
  restRefs,
  handler,
}: Props): void => {
  useEffect(() => {
    const listener = (event: Event): void => {
      const checkResRefsContains = (): boolean => {
        return (restRefs ?? []).every(
          (item) => item && item.current && !item.current.contains(event.target as Node)
        );
      };

      if (
        targetRef.current &&
        !targetRef.current.contains(event.target as Node) &&
        checkResRefsContains()
      ) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [targetRef, restRefs, handler]);
};
