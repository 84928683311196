export const fontSize = {
  "tablet-body": [
    "0.25rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "tablet-heading1": [
    "0.375rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "tablet-heading2": [
    "0.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "tablet-heading3": [
    "0.75rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "tablet-heading4": [
    "1rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "tablet-heading5": [
    "1.25rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "xs-bold": [
    "0.6rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "xs-semibold": [
    "0.6rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "xs-medium": [
    "0.6rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "xs-regular": [
    "0.6rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "sm-bold": [
    "0.8rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "sm-semibold": [
    "0.8rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "sm-medium": [
    "0.8rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "sm-regular": [
    "0.8rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "md-bold": [
    "0.9rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "md-semibold": [
    "0.9rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "md-medium": [
    "0.9rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "md-regular": [
    "0.9rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "lg-bold": [
    "1rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "lg-semibold": [
    "1rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "lg-medium": [
    "1rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "lg-regular": [
    "1rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "xl-bold": [
    "1.25rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "xl-semibold": [
    "1.25rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "xl-medium": [
    "1.25rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "xl-regular": [
    "1.25rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "2xl-bold": [
    "1.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "2xl-semibold": [
    "1.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "2xl-medium": [
    "1.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "2xl-regular": [
    "1.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "3xl-bold": [
    "2rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "3xl-semibold": [
    "2rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "3xl-medium": [
    "2rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "3xl-regular": [
    "2rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "4xl-bold": [
    "2.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "4xl-semibold": [
    "2.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "4xl-medium": [
    "2.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "4xl-regular": [
    "2.5rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
  "5xl-bold": [
    "3rem",
    {
      lineHeight: "1.25",
      fontWeight: "700",
    },
  ],
  "5xl-semibold": [
    "3rem",
    {
      lineHeight: "1.25",
      fontWeight: "600",
    },
  ],
  "5xl-medium": [
    "3rem",
    {
      lineHeight: "1.25",
      fontWeight: "500",
    },
  ],
  "5xl-regular": [
    "3rem",
    {
      lineHeight: "1.25",
      fontWeight: "400",
    },
  ],
};
