import { isUndefined, getPaddingStyle } from "@landwich/shared/utils";
import type { fontSize } from "@landwich/styles";
import { type PropsWithChildren, type ButtonHTMLAttributes } from "react";
import cn from "classnames";
import { Icon, type IconNames } from "../Icon";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconNames;
  color?:
    | "black"
    | "white"
    | "white-black"
    | "green"
    | "charcoal_600"
    | "charcoal_500"
    | "red"
    | "white-red"
    | "transparent";
  rounded?: "rounded" | "rounded-lg" | "rounded-2xl" | "rounded-full";
  textVariant?: `text-${keyof typeof fontSize}`;
  paddingX?: number;
  paddingY?: number;
  iconSize?: number;
  iconGap?: number;
  block?: boolean;
  textAlign?: "left" | "center" | "right";
}

export const Button = ({
  icon,
  color = "white",
  paddingX = 4,
  paddingY = 4,
  textVariant = "text-lg-medium",
  rounded = "rounded-full",
  iconSize = 19,
  iconGap = 2,
  disabled,
  block,
  textAlign = "center",
  children,
  className,
  ...rest
}: PropsWithChildren<Props>) => {
  const colorMap = {
    black:
      "bg-black text-white border border-black hover:bg-charcoal_800 hover:border-charcoal_800",
    white:
      "bg-white text-charcoal_400 border border-charcoal_400 hover:text-charcoal_500 hover:border-charcoal_500",
    "white-black":
      "bg-white text-black border border-charcoal_400 hover:bg-charcoal_50 hover:border-charcoal_500",
    green:
      "bg-viridian_500 text-white border border-viridian_500 hover:bg-viridian_600 hover:border-viridian_600",
    charcoal_600:
      "bg-charcoal_600 text-white border border-charcoal_600 hover:bg-charcoal_700 hover:border-charcoal_700",
    charcoal_500:
      "bg-charcoal_500 text-white border border-charcoal_500 hover:bg-charcoal_600 hover:border-charcoal_600",
    red: "bg-cerise_500 text-white border border-cerise_500 hover:bg-cerise_600 hover:border-cerise_600",
    "white-red":
      "bg-white text-cerise_500 border border-cerise_500 hover:border-cerise_600 hover:text-cerise_600",
    transparent:
      "bg-transparent text-white border border-white hover:bg-white-alpha-15",
  };

  const disabledClasses =
    "cursor-not-allowed !bg-charcoal_200 !border-charcoal_200 !text-charcoal_400 !hover:bg-charcoal_200 !hover:border-charcoal_200";

  const buttonStyle = getPaddingStyle({ paddingX, paddingY });

  return (
    <button
      className={cn(
        `${colorMap[color]} ${rounded} h-fit ${className} transition-all`,
        {
          [disabledClasses]: disabled,
          "w-full": block,
          "text-left": textAlign === "left",
          "text-center": textAlign === "center",
          "text-right": textAlign === "right",
        }
      )}
      disabled={disabled}
      style={buttonStyle}
      type="button"
      {...rest}
    >
      {!isUndefined(icon) ? (
        <div
          className={cn(`flex justify-center items-center`, {
            "!gap-0": isUndefined(children),
          })}
          style={{ gap: `${iconGap * 0.25}rem` }}
        >
          <Icon color="inherit" icon={icon} size={iconSize} />
          {children && <div className={textVariant}>{children}</div>}
        </div>
      ) : (
        <div className={textVariant}>{children}</div>
      )}
    </button>
  );
};
